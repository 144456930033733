
import AuthService from "@/auth/AuthService";
import OJ1010S from "@/service/OJ1010Service";
import ZFunc from  "@/service/ZFunc"
import { Vue } from "vue-class-component";
import zCONST from "@/const";

export default class OJ1010F extends Vue {
    // クラス定義
    AuthS: AuthService = new AuthService();
    OJ1010S: OJ1010S = new OJ1010S();
    ZF: ZFunc = new ZFunc();

    //ログイン情報
    mAREAKY: any = 0;
    mTOKUKY: any = 0;
    mUSERKY: number = 0;

    //検索フィールド変数
    mRetKOJINM: any = "";  
    mRetOKYAKY: any = "";

    // 一覧フィールド変数
    mDgList: any[] = [];            // データグリッドソース
    loadingFlg: boolean = false;    // ロード中フラグ

    // 変数定義
    $cookies: any;    // cookie

    mounted(): void {
        this.fncInit();
    }

    // 初期表示処理
    fncInit(): void {
        //ログイン情報取得
        this.mUSERKY = this.$cookies.get("USERKY");
        let wParam = { 
            USERKY: this.mUSERKY
        };
        this.OJ1010S.fncGetUserInfo(wParam).then((data: any) => {
            this.mAREAKY = data.AREAKY;
            this.mTOKUKY = data.TOKUKY;
            this.fncGetList();
        });
    }

    // おくやみ取得処理
    fncGetList(): void {
        let wParam = { 
            AREAKY: 0
            ,TOKUKY: this.mTOKUKY
            ,KOJINM: this.mRetKOJINM
            ,OKYAKY: this.mRetOKYAKY
        };
        this.loadingFlg = true;
        this.OJ1010S.fncGetList(wParam).then((data: any) => {
            this.mDgList = data.dgList;
        }).finally(() => {this.loadingFlg = false;});
    }

    fncClear(): void{
        this.mRetKOJINM ="";
        this.mRetOKYAKY ="";
        this.fncGetList();
    }

    // 掲載日
    fncSetKOKADT(pKOKAKB: number,pKOKADT:any,pKOKATM:any){
        let wRet = '';
        if(pKOKAKB == 1){
            wRet =  this.ZF.zStrDtToSlash(pKOKADT) + " " + pKOKATM;
        }
        return wRet;
    }

    // 詳細表示
    fncShowShosai(pOKYAKY: number): void {
        // 詳細画面を新規モードで呼び出す
        const zZ1011F: any = this.$refs["OJ1011F"];
        let wMODEXX: number;
        if (pOKYAKY == 0) {
            wMODEXX = zCONST.cMODE_ADD;
        } else {
            wMODEXX = zCONST.cMODE_DISP;
        }

        console.log(pOKYAKY);

        //ダイアログを表示
        zZ1011F.fncShow(
        {
            MODEXX: wMODEXX,
            OKYAKY: pOKYAKY,
        },
        () => {
            //登録成功
            this.fncGetList();
        }
        );
    }

    // 同意書PDF出力
    fncDouishoPDF(pOKYAKY:number):void{
         // 画面を呼び出す
        const zZ1014F: any = this.$refs["OJ1014F"];
        //ダイアログを表示
        zZ1014F.fncShow(
            {
                OKYAKY: pOKYAKY
            },
            () => {
                this.fncGetList();
            }
        );
    }

     //　公開
    fncKokai(pOKYAKY: number) {
        //  確認画面を新規モードで呼び出す
        const zZ1012F: any = this.$refs["OJ1012F"];
        //ダイアログを表示
        zZ1012F.fncShow(
                {
                    OKYAKY: pOKYAKY
                    ,TOKUKY: this.mTOKUKY
                    ,AREAKY: this.mAREAKY
                    ,USERKT: this.$cookies.get("USERKY")
                },
                () => {
                    this.fncGetList();
                });
    }

    // 新聞社用PDF出力
    fncShowPDF(pOKYAKY:number):void{
         // 画面を呼び出す
        const zZ1013F: any = this.$refs["OJ1013F"];
        //ダイアログを表示
        zZ1013F.fncShow(
            {
                OKYAKY: pOKYAKY
            },
            () => {
                this.fncGetList();
            }
        );
    }

    //　公開取消
    fncTorikeshi(pOKYAKY: number) {
        if (confirm("公開を取り消します。よろしいですか?") == true) {
            document.getElementById("UpMsgBox")?.scrollIntoView({
                behavior: "smooth",
                block: 'start'
            })
            let wParam = { 
                OKYAKY: pOKYAKY
                ,AREAKY: this.mAREAKY
                ,USERKY: this.mUSERKY
                ,KOKAKB: 0
            };
            // 登録処理
            this.OJ1010S.fncPageCreate(wParam).then((data: any) => {
                this.OJ1010S.fncPageUpload(wParam).then((data: any) => {        
                    alert("取り消しました。");
                    this.fncGetList();
                })
            });
        }
    }
}
